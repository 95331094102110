import React from 'react';
import { Paper, Grid, Typography, Avatar } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ScheduleCard from './ScheduleCard';

import PhotoGallery from './PhotoGallery';

import '../../styles/LandingPage.css';

import eric from '../../photos/eric.jpg';

const styles = {
  paper: {
    background: 'rgba(255, 255, 255, 0.15)',
    my: '5vh',
    mx: 'auto',
    width: '95%',
  },
  mainPaper: {
    py: 1,
    px: 1,
  },
  hrBio: {
    color: 'rgb(15, 15, 255)',
    background: 'rgb(15, 15, 255)',
    borderColor: 'rgb(15, 15, 255)',
    width: '90%',
    marginBottom: '3vh',
  },
  schedWrapper: {
    margin: '32px 0 32px 0',
  },
  bio: {
    color: 'white',
    fontSize: '3vh',
  },
  bioWrapper: {
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '5vh',
    // textAlign: 'left',
    paddingBottom: '5vh',
  },
  ppic: {
    width: '35vh',
    height: '35vh',
    margin: '5vh auto 5vh auto',
  },
};

const fullSchedule = [
  {
    title: 'Kids Jiu Jitsu/Muay Thai (5 -10 years)',
    blocks: [
      {
        days: 'Monday - Thursday',
        times: ['5:00 PM'],
      },
    ],
  },
  {
    title: 'Jiu Jitsu (All levels)',
    blocks: [
      {
        days: 'Monday, Wednesday',
        times: ['11:00 AM', '6:00 PM'],
      },
      {
        days: 'Tuesday, Thursday',
        times: ['6:00 PM'],
      },
      {
        days: 'Friday',
        times: ['11:00 AM'],
      },
    ],
  },
  {
    title: 'Advanced Jiu Jitsu',
    blocks: [
      {
        days: 'Monday, Wednesday',
        times: ['7:00 PM'],
      },
    ],
  },
  {
    title: 'Combat Jiu Jitsu/MMA/Muay Thai',
    blocks: [
      {
        days: 'Tuesday, Thursday',
        times: ['7:30 PM'],
      },
    ],
  },
  {
    title: 'Competition Training',
    blocks: [
      {
        days: 'Friday',
        times: ['6:30 PM'],
      },
    ],
  },
  {
    title: 'Women Only',
    blocks: [
      {
        days: 'Sunday',
        times: ['9:00 AM'],
      },
    ],
  },
  {
    title: 'Conditioning',
    blocks: [
      {
        days: 'Tuesday, Thursday',
        times: ['11:00 AM'],
      },
      {
        days: 'Saturday',
        times: ['10:00 AM'],
      },
    ],
  },
  {
    title: 'Open Mat',
    blocks: [
      {
        days: 'Saturday (Q/A & Open mat), Sunday',
        times: ['11:00 AM'],
      },
    ],
  },
];

function LandingPage(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div id='main'>
      <Paper elevation={3} id='top-paper' sx={{ ...styles.mainPaper, ...styles.paper }}>
        <div style={styles.schedWrapper}>
          <Grid
            container
            justifyContent='space-around'
            sx={{
              width: '100%',
              my: 0,
              mx: 'auto',
              pl: mobile ? 0 : 1.5,
            }}
          >
            {fullSchedule.map((section) => (
              <ScheduleCard key={section.title} title={section.title} schedule={section.blocks} />
            ))}
          </Grid>
        </div>
      </Paper>
      <Paper elevation={3} id='bioPaper' sx={styles.paper}>
        <br />
        <Avatar alt='Eric Longar' src={eric} sx={styles.ppic} id='avatar' />
        <hr style={styles.hrBio} />
        <div style={styles.bioWrapper}>
          {mobile ? (
            <Accordion
              sx={{
                width: '100%',
                background: 'rgba(15, 15, 15, 0.8)',
                color: 'white',
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
                <Typography variant='h6'>Eric's Bio</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={styles.bio} variant='body1' align='center' id='bio'>
                  Eric Longar is a professional Jiu Jitsu athlete with over five years of coaching experience. Originally introduced to Jiu jitsu at Tenth Planet Saint Louis in
                  2011, he then trained under Professor Bobby Willams Jr (Ricardo Almiada affiliation) where he helped organize Tenth Planet Tokyo’s Hot Box. On his return to the
                  US, Eric trained under Ronin Jiu Jitsu (Gokor Chivichyan affiliation) before he returned to Tenth Planet in Las Vegas. There, he was also coached by Casey
                  Halstead (an Eddie Bravo first degree black belt) who actively works with some of the most experienced and well-respected MMA fighters in the world.
                  {/* <br /> */}
                  {/* <br /> */}
                  He is an active Jiu Jitsu competitor who has competed in Japan and all over the United states, most notably in the Professional Grappling Federation Season One.
                  {/* <br /> */}
                  {/* <br /> */}
                  Taking pride in developing homegrown MMA talents and Jiu Jitsu athletes from day one, he works closely with everyone from students with no Martial Arts experience
                  to athletes at the peak of their careers. On top of being a master of his craft, Eric’s style of coaching and instruction pushes the limits of his team to come
                  together and work to build the community up. He has a reputation of hard work while promoting a family friendly environment where people from all walks of life
                  have fun while striving for greatness. He lives a life devoted to inspiring his team to live a mentally and physically healthy lifestyle and pursue higher
                  personal and professional goals.
                </Typography>
              </AccordionDetails>
            </Accordion>
          ) : (
            <Typography sx={styles.bio} variant='body1' align='center' id='bio'>
              Eric Longar is a professional Jiu Jitsu athlete with over five years of coaching experience. Originally introduced to Jiu jitsu at Tenth Planet Saint Louis in 2011,
              he then trained under Professor Bobby Willams Jr (Ricardo Almiada affiliation) where he helped organize Tenth Planet Tokyo’s Hot Box. On his return to the US, Eric
              trained under Ronin Jiu Jitsu (Gokor Chivichyan affiliation) before he returned to Tenth Planet in Las Vegas. There, he was also coached by Casey Halstead (an Eddie
              Bravo first degree black belt) who actively works with some of the most experienced and well-respected MMA fighters in the world.
              {/* <br /> */}
              {/* <br /> */}
              He is an active Jiu Jitsu competitor who has competed in Japan and all over the United states, most notably in the Professional Grappling Federation Season One.
              {/* <br /> */}
              {/* <br /> */}
              Taking pride in developing homegrown MMA talents and Jiu Jitsu athletes from day one, he works closely with everyone from students with no Martial Arts experience to
              athletes at the peak of their careers. On top of being a master of his craft, Eric’s style of coaching and instruction pushes the limits of his team to come together
              and work to build the community up. He has a reputation of hard work while promoting a family friendly environment where people from all walks of life have fun while
              striving for greatness. He lives a life devoted to inspiring his team to live a mentally and physically healthy lifestyle and pursue higher personal and professional
              goals.
            </Typography>
          )}
        </div>
      </Paper>
      <Paper elevation={3} sx={styles.paper}>
        <PhotoGallery />
      </Paper>
      {/* <a
        style={{ fontSize: "3vh" }}
        href="https://10thPlanetCrystalCity.zenplanner.com/zenplanner/portal/sign-up-now.cfm"
        >
        Sign Up
      </a> */}
    </div>
  );
}

export default LandingPage;
