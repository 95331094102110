import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    common: {
      red: 'rgb(255, 0, 0)',
    },
    primary: {
      main: 'rgb(25, 25, 255)',
    },
    secondary: {
      main: 'rgb(255, 25, 25)',
    },
  },
});

export default theme;
