import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

const ScheduleCardBackground = ({ children }) => {
  return (
    <Box
      sx={{
        minHeight: '268px',
        width: 'calc(90% - 2px)',
        color: 'white',
        background: 'rgba(15, 15, 15, 0.8)',
        padding: '16px',
      }}
    >
      {children}
    </Box>
  );
};

const ScheduleCard = ({ title, schedule }) => {
  return (
    <Grid item xs={12} md={3} sx={{ my: 1 }}>
      <ScheduleCardBackground>
        <Typography variant='h4' align='center'>
          {title}
        </Typography>
        <hr
          style={{
            color: 'rgb(15, 15, 255)',
            background: 'rgb(15, 15, 255)',
            borderColor: 'rgb(15, 15, 255)',
          }}
        />
        {schedule.map((block) => (
          <div key={`${title}-${block.days}`}>
            <Typography sx={{ ml: '5%' }} variant='h6' align='left'>
              {block.days}
            </Typography>
            {block.times.map((time) => (
              <Typography key={`${title}-${block.days}-${time}`} variant='subtitle1' sx={{ ml: '8%' }} align='left'>
                - {time}
              </Typography>
            ))}
          </div>
        ))}
      </ScheduleCardBackground>
    </Grid>
  );
};

export default ScheduleCard;
