import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneTwoToneIcon from '@mui/icons-material/PhoneTwoTone';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { isMobileStrict } from '../utils/isMobile';

const styles = {
  icons: {
    color: 'white',
  },
  paper: {
    background: 'rgba(40, 40, 40, 0.8)',
    padding: '8px 5px 5px 5px',
    position: 'fixed',
    top: 0,
    left: 0,
    transform: isMobileStrict() ? 'translate(calc(98vw - 100%), 65vh)' : 'translate(calc(50vw - 50%), 93.5vh)',
    zIndex: 999,
  },
  gItem: {
    width: isMobileStrict() ? '10px' : null,
  },
};
export default function Contact() {
  const gridItemSize = isMobileStrict() ? 12 : 3;

  return (
    <Paper elevation={3} sx={styles.paper}>
      <Grid container>
        <Grid item sx={styles.gItem} xs={gridItemSize}>
          <a id='address-text' href='https://maps.app.goo.gl/VzcQ3QKPkYf6mB35A'>
            <RoomOutlinedIcon sx={styles.icons} fontSize='large' />
          </a>
        </Grid>

        <Grid item sx={styles.gItem} xs={gridItemSize}>
          <a href='mailto: coacheric10p@gmail.com'>
            <EmailOutlinedIcon sx={styles.icons} fontSize='large' />
          </a>
        </Grid>

        <Grid item sx={styles.gItem} xs={gridItemSize}>
          <a href='tel: 6366983912'>
            <PhoneTwoToneIcon sx={styles.icons} fontSize='large' />
          </a>
        </Grid>

        <Grid item sx={styles.gItem} xs={gridItemSize}>
          <a href='https://www.instagram.com/ericlongar_10p/'>
            <InstagramIcon sx={styles.icons} fontSize='large' />
          </a>
        </Grid>
      </Grid>
    </Paper>
  );
}
