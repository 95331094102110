import React from 'react';
import '../styles/App.css';
// import Tabs from './Tabs';
import { ThemeProvider } from '@emotion/react';
import theme from './customTheme';
import JiuJitsu from './jj';
import Contact from './Contact';
import text from '../photos/text.svg';
import backGroundImg from '../bg.png';

function App() {
  return (
    <div>
      <div style={{ zIndex: -50, height: '100vh', width: '100vw', position: 'fixed', backgroundImage: `url(${backGroundImg})` }} />
      <ThemeProvider theme={theme}>
        {/* <Tabs /> */}
        <Contact />
        <img style={{ height: '40vh', width: '100%' }} className={'bg'} id='logo' src={text} alt='logo' />
        <JiuJitsu />
      </ThemeProvider>
    </div>
  );
}

export default App;
