import { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';

import { isMobile } from '../../utils/isMobile';

const pic1 = 'https://i.imgur.com/yTfMPxJ.jpg';
const pic2 = 'https://i.imgur.com/tlAWiOT.jpg';
const pic3 = 'https://i.imgur.com/PFYGrk0.jpg';
const pic4 = 'https://i.imgur.com/OinQ50k.jpg';
const pic5 = 'https://i.imgur.com/ecRGP3c.jpg';
const pic6 = 'https://i.imgur.com/jH2r1E8.jpg';
const pic7 = 'https://i.imgur.com/XSyp59I.jpg';
const pic8 = 'https://i.imgur.com/HSNn0Dt.jpg';

const photos = [pic1, pic2, pic3, pic4, pic5, pic6, pic7, pic8];

const styles = {
  main: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    pr: 1,
  },
  pic: {
    width: '100%',
    pointerEvents: 'all',
    userSelect: 'none',
  },
  expanded: {
    pointerEvents: 'all',
    maxWidth: isMobile() ? '100vw' : '90vw',
    maxHeight: isMobile() ? '100vh' : '90vh',
  },
};

const Expand = ({ pic, ind, open, handleClose }) => {
  return (
    <Backdrop
      sx={(theme) => ({ zIndex: theme.zIndex.drawer + 1 })}
      open={open[`pic${ind + 1}`] || false}
      onClick={handleClose}
    >
      <img src={pic} alt={`Eric${ind + 1}-expanded`} style={styles.expanded} />
    </Backdrop>
  );
};

export default function PhotoGallery(props) {
  const [open, setOpen] = useState({});

  const closeAll = () => {
    setOpen({
      pic1: false,
      pic2: false,
      pic3: false,
      pic4: false,
      pic5: false,
      pic6: false,
      pic7: false,
      pic8: false,
    });
  };

  useEffect(() => {
    closeAll();
  }, []);

  const handleClick = (name) => {
    setOpen({ ...open, [name]: true });
  };

  const handleClose = () => {
    closeAll();
  };

  return (
    <>
      {photos.map((pic, ind) => {
        return (
          <Expand
            key={`expanded-eric-${ind}`}
            pic={pic}
            ind={ind}
            open={open}
            handleClose={handleClose}
          />
        );
      })}
      <Grid sx={styles.main} container justifyContent='space-around' spacing={1}>
        <Grid item container direction='column' xs={12} md={3}>
          <Grid item>
            <img style={styles.pic} src={pic1} alt='Eric1' onClick={() => handleClick('pic1')} />
          </Grid>
          <Grid item>
            <img style={styles.pic} src={pic2} alt='Eric2' onClick={() => handleClick('pic2')} />
          </Grid>
        </Grid>

        <Grid item container direction='column' xs={12} md={3}>
          <Grid item>
            <img style={styles.pic} src={pic5} alt='Eric5' onClick={() => handleClick('pic5')} />
          </Grid>
          <Grid item>
            <img style={styles.pic} src={pic6} alt='Eric6' onClick={() => handleClick('pic6')} />
          </Grid>
        </Grid>

        <Grid item container direction='column' xs={12} md={3}>
          <Grid item>
            <img style={styles.pic} src={pic3} alt='Eric3' onClick={() => handleClick('pic3')} />
          </Grid>
          <Grid item>
            <img style={styles.pic} src={pic4} alt='Eric4' onClick={() => handleClick('pic4')} />
          </Grid>
          <Grid item>
            <img style={styles.pic} src={pic7} alt='Eric7' onClick={() => handleClick('pic7')} />
          </Grid>
        </Grid>

        <Grid item container direction='column' xs={12} md={3}>
          <Grid item xs={12} md={3}>
            <img style={styles.pic} src={pic8} alt='Eric8' onClick={() => handleClick('pic8')} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
